<template>
  <div>
    <px-capa :banner-capa="header">
      <template v-slot:title>
        Compre granalha para sua empresa com a Proxpect
      </template>
      <template v-slot:text>
        Cadastre-se e compre agora, sem precisar de cotações
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        link="/q/c/28"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12" phone-number="5548992120020"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-home-page-block
        show-link
        link="/q/d/11"
        class="mt-10"
        :query="queryOutrosProdutos"
        title="Outros produtos"
        :qtd-ofertas="20"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import RdDuvidaFormSubscription from '@/modules/integration/rd/RdDuvidaFormSubscription.vue';
import LandingPageWhatsapp from '@/modules/landing-page/LandingPageWhatsapp.vue';
import header from '@/assets/landing-page/winoa/banner-winoa.png';
import banner230 from '@/assets/landing-page/winoa/carrosel/granalha-e-230.png';
import banner40 from '@/assets/landing-page/winoa/carrosel/granalha-g-40.png';
import banner170 from '@/assets/landing-page/winoa/carrosel/granalha-s-170.png';
import banner25 from '@/assets/landing-page/winoa/carrosel/granalha-a-25.png';

export default {
  data() {
    return {
      header,
      query: 'disponivel is true and categoria.id = 28 and fornecedor.id = 564 order by random',
      queryOutrosProdutos: 'categoria in (124, 714, 198, 856, 69) and disponivel is true and fornecedor.id = 564 order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos que sua empresa precisa',
      banners: [
        {
          foto: banner25,
          link: '/oferta/106686',
        },
        {
          foto: banner40,
          link: '/oferta/106687',
        },
        {
          foto: banner230,
          link: '/oferta/106692',
        },
        {
          foto: banner170,
          link: '/oferta/106690',
        },
      ],
    };
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    pxComoFunciona: ComoFunciona,
    pxVantagens: Vantagens,
    pxCapa: Capa,
    pxFaqLandingPage: FaqLandingPage,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
